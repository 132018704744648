import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {LoyaltyProgramService} from "@services/http/loyalty-program.service";
import {Service} from "@builder-services/core/service";
import {LocalStorageKeys} from "@models/LocalStorageKeys";
import {LoyaltyOutcomeSettings, Integrations} from "@models/LoyaltyProgram";
import {BuilderStateService} from "@builder-services/core/builder-state.service";
import {SpendingPointsService} from "@services/spending-points/spending-points.service";
import {LocalStorageService} from "../../../core/util-services/local-storage.service";
import { UtilService } from "../../../core/util-services/util.service";

@Component({
    selector: "app-loyalty-program",
    templateUrl: "loyalty-program.component.html",
    styleUrls: ["loyalty-program.component.scss"],
})

export class LoyaltyProgramComponent implements OnChanges {
    @Output() loyaltyData = new EventEmitter<LoyaltyOutcomeSettings>();
    @Output() integrationsExist = new EventEmitter<boolean>();
    @Input() outcomeId: string;
    @Input() submitLoyaltyData: boolean;
    @Input() customClass?: string = "";

    campaignId: string;
    companyId: string;
    companyIntegrations: Integrations[];
    loyaltyOutcomeSettings: LoyaltyOutcomeSettings[];
    integrationOutcomeId: string;
    integrationsLoaded = false;
    settingsLoaded = false;
    hasIntegrations= false;
    spendingPoints: any;

    loyaltyForm: FormGroup = new FormGroup({
        integration: new FormControl(null),
        identifier: new FormControl("", Validators.required),
        points: new FormControl(null),
    });

    constructor(
        private loyaltyService: LoyaltyProgramService,
        private spendingService: SpendingPointsService,
        private state: BuilderStateService,
        private localStorage: LocalStorageService,
        private service: Service,
        private utils: UtilService,
    ) {
    }

    ngOnInit(){
        this.campaignId = this.service.campaignId;
        this.companyId = this.localStorage.getItem(
            LocalStorageKeys.COMPANY_VIEW_ID
        );

        this.initializeLoyaltyForm();
        this.getIntegrations();
        this.getLoyaltyOutcomeSettings();
        this.getSpendingPoints();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.submitLoyaltyData) {
            const { currentValue } = changes.submitLoyaltyData;
            if (currentValue && this.loyaltyForm.valid) {
                this.emitFormData();
            }
        }
    }

    private initializeLoyaltyForm(): void {
        this.loyaltyForm = new FormGroup({
            integration: new FormControl(null),
            identifier: new FormControl(""),
            points: new FormControl(null),
        });

    }

    isRegisterPageIncluded(included) {
        const { pages } = this.service.config;
        const registerPage = pages.find((page) => page.key === "register");
        const introPage = pages.find((page) => page.key === "introduction");
        const gameViewPage = pages.find((page) => page.key === "game-view");

        const registerPageIndex = pages.findIndex((page) => page.key === "register");

        if (registerPage && this.hasIntegrations) {

            registerPage.included = included;
            if (!this.utils.isCalendarGame(this.service.config.campaignType)) {
                if (registerPage.included) {
                    if (registerPageIndex === 4) {
                        introPage.nextPage = "register";
                        gameViewPage.nextPage = "outcome";
                    } else if (registerPageIndex === 5) {
                        introPage.nextPage = "game-view";
                        gameViewPage.nextPage = "register";
                    }
                } else {
                    introPage.nextPage = "game-view";
                    gameViewPage.nextPage = "outcome";
                }
                this.state.$configurationModified.next(true);
            } else {
                if (!registerPage.included){
                    gameViewPage.nextPage = "outcome";
                }
            }
        }
    }

    getSpendingPoints(){
        this.spendingService.getSpendingPoints(this.campaignId).subscribe({
            next: (response) => {
                this.spendingPoints = response;
            },
        });
    }

    getIntegrations(): void {
        if (this.companyId) {
            this.loyaltyService.getCompanyIntegrations(this.companyId).subscribe(
                (integrations) => {
                    this.companyIntegrations = integrations;
                    this.hasIntegrations = integrations.length > 0;
                    this.integrationsLoaded = true;
                    this.tryFilterLoyaltySettings();
                    this.integrationsExist.emit(this.hasIntegrations);
                },
                () => {
                    this.hasIntegrations = false;
                    this.integrationsExist.emit(this.hasIntegrations);
                }
            );
        }
    }

    getLoyaltyOutcomeSettings(): void {
        if (this.campaignId) {
            this.loyaltyService.getLoyaltyOutcomeSettings(this.campaignId).subscribe({
                next: (settings) => {
                    this.loyaltyOutcomeSettings = settings || [];
                    this.settingsLoaded = true;
                    this.tryFilterLoyaltySettings();
                    this.isRegisterPageIncluded(this.loyaltyOutcomeSettings.length === 0);
                },
                error: () => {
                    this.loyaltyOutcomeSettings = [];
                    this.settingsLoaded = true;
                },
            });
        } else {
            this.loyaltyOutcomeSettings = [];
            this.settingsLoaded = false;
        }
    }

    filterLoyaltySettings(outcomeId: string): void {
        if (this.loyaltyOutcomeSettings) {
            const filteredSetting = this.loyaltyOutcomeSettings.find(
                (setting) => setting.outcomeId === outcomeId
            );
            if (filteredSetting) {
                const integrations = this.companyIntegrations.find(
                    (integration) => integration.id === filteredSetting.companyIntegrationId
                );
                this.integrationOutcomeId = filteredSetting.id;

                const pointsValue = filteredSetting.points !== null ? filteredSetting.points : "" ;
                this.loyaltyForm.patchValue({
                    integration: integrations,
                    identifier: filteredSetting.identifier,
                    points: pointsValue,
                });
            }
        }
    }

    createLoyaltyOutcomeSettings(loyaltyData: LoyaltyOutcomeSettings): void {
        this.loyaltyService.createLoyaltyOutcomeSettings(loyaltyData).subscribe({
            next: () => {
                this.loyaltyData.emit(loyaltyData);
                this.getLoyaltyOutcomeSettings();
            },
        });
    }

    deleteLoyaltyOutcomeSetting(integrationOutcomeId: string): void {
        this.loyaltyService.deleteLoyaltyOutcomeSetting(integrationOutcomeId).subscribe({
            next: () => {
                this.integrationOutcomeId = null;
                this.loyaltyOutcomeSettings = [];
                this.getLoyaltyOutcomeSettingsPostDelete();
            },
        });
    }

    getLoyaltyOutcomeSettingsPostDelete(): void {
        this.loyaltyService.getLoyaltyOutcomeSettings(this.campaignId).subscribe({
            next: (settings) => {
                this.loyaltyOutcomeSettings = settings || [];
                if (this.spendingPoints === null) {
                    this.isRegisterPageIncluded(this.loyaltyOutcomeSettings.length === 0);
                }
            },
            error: () => {
                this.loyaltyOutcomeSettings = [];
                this.isRegisterPageIncluded(true);
            },
        });
    }

    tryFilterLoyaltySettings(): void {
        if (this.integrationsLoaded && this.settingsLoaded) {
            if (this.outcomeId) {
                this.filterLoyaltySettings(this.outcomeId);
            }
        }
    }

    updateLoyaltySettings(updateData: LoyaltyOutcomeSettings): void {
        this.loyaltyService.updateLoyaltyOutcomeSettings(updateData).subscribe({
            next: () => {
                this.loyaltyData.emit(updateData);
            },
        });
    }

    emitFormData(): void {
        const formValue = this.loyaltyForm.value;

        if (formValue.integration === "") {
            if (this.integrationOutcomeId) {
                this.deleteLoyaltyOutcomeSetting(this.integrationOutcomeId);
            }
        } else if (formValue.integration === null) {
            return;
        } else {
            const loyaltyData: LoyaltyOutcomeSettings = {
                companyIntegrationId: formValue.integration.id,
                identifier: formValue.identifier,
                points: formValue.points,
                campaignId: this.campaignId,
                outcomeId: this.outcomeId,
                integrationKey: formValue.integration.loyaltyIntegration.key,
            };

            if (this.integrationOutcomeId) {
                const updateData: LoyaltyOutcomeSettings = {...loyaltyData, id: this.integrationOutcomeId};
                this.updateLoyaltySettings(updateData);
            } else {
                this.createLoyaltyOutcomeSettings(loyaltyData);
            }
        }
    }
}
